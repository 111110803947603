import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CRestaurantPlan,
  CGeneralCard,
  CAnchorNav,
  CSectTitle04,
  CInlineDefinition,
  CInlineDefinition02,
  LContact04,
} from '../../../components/_index';
import { restaurantPlanEventChoice } from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const eventclass = ['法事'];

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'ご法要のご案内',
            sub: <>MEMORIAL SERVICE</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/memorial/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/memorial/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect03 u_bgGray">
        <LWrap>
          <p className="u_tac_pc u_fs16">
            故人を偲ぶご法要の集いに、落ち着いた雰囲気のレストランにて特別料理をご用意いたします。
            <br />
            和やかに、心ゆくまで思い出を語り合っていただけますよう、きめ細かくおもてなしをさせていただきます。
          </p>
        </LWrap>
      </section>
      <section className="l_sect u_pt50">
        <LWrap>
          <CAnchorNav
            exClass="u_mb0"
            data={[
              {
                title: <>日本料理 源氏香</>,
                link: {
                  href: '/restaurants/memorial#genjikoh',
                },
              },
              {
                title: <>中国料理 桂花苑</>,
                link: {
                  href: '/restaurants/memorial#kei_ka_en',
                },
              },
            ]}
          />
          <div id="genjikoh" className="u_pt80">
            <CSectTitle
              type="ja"
              title={{
                ja: '日本料理 源氏香',
              }}
            />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/memorial/img_memorial.png',
                    alt: '',
                  },
                  link: {
                    href: '#',
                  },
                  title: '日本料理 源氏香',
                  text: (
                    <>
                      日本庭園が望める静かな佇まいでの和食は、海外からのお客様にもよろこんでいただけることでしょう。
                      <br />
                      天婦羅コーナーでは揚げたてをたのしんでいただけます。
                      <br />
                      また、お座敷もご用意してございます。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['日本料理 源氏香'],
                eventclass
              )}
              eventflag={true}
            />
          </div>

          <div id="kei_ka_en" className="u_pt80">
            <CSectTitle
              type="ja"
              title={{
                ja: '中国料理 桂花苑',
              }}
            />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/memorial/img_memorial02.png',
                    alt: '',
                  },
                  link: {
                    href: '#',
                  },
                  title: '中国料理 桂花苑',
                  text: (
                    <>
                      「食は広州にあり」といわれるほど、奥が深く、またバラエティに富む広東料理。
                      <br />
                      魚介類と高度な技術が揃えばこそ創りだすことができる豊かな味わいを、心ゆくまでご賞味ください。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['中国料理 桂花苑'],
                eventclass
              )}
              eventflag={true}
            />
          </div>
          <CSectTitle03 title="その他オプション" exClass="u_mt60" />
          <div className="c_inlineDefinitionUnit03 c_inlineDefinitionUnit03__col2 u_mb0">
            <div className="col">
              <CSectTitle04 title={'オプション'} tag={'h3'} />
              <CInlineDefinition02
                exClass="u_mb20"
                col={1}
                data={[
                  {
                    title: '2時間のフリードリンク',
                    text: 'お一人様 3,500円',
                  },
                ]}
              />
              <ul className="c_noteList">
                <li>
                  ビール(中瓶)、ワイン(赤・白)、焼酎(芋・麦)、ウイスキー、日本酒(日本料理
                  源氏香にてご提供)または紹興酒(中国料理
                  桂花苑にてご提供)、ソフトドリンク
                </li>
                <li>料金には消費税・サービス料が含まれます。</li>
              </ul>
            </div>
          </div>

          <div className="c_inlineDefinitionUnit03 c_inlineDefinitionUnit03__col1 u_mt60">
            <div className="col">
              <CSectTitle04 title={'各種ご手配料金'} tag={'h3'} />
              <CInlineDefinition02
                exClass="u_mb20"
                col={2}
                data={[
                  {
                    title: '祭壇装花 一対',
                    text: '11,000円～',
                  },
                  {
                    title: '献花一輪 バラ',
                    text: '660円',
                  },
                  {
                    title: '献花一輪 カーネーション',
                    text: '550円',
                  },
                  {
                    title: '献花一輪 デンファレ',
                    text: '660円',
                  },
                  {
                    title: '献花一輪 菊',
                    text: '660円',
                  },
                  {
                    title: 'お引物',
                    text: '1,620円～',
                  },
                ]}
              />
              <ul className="c_noteList">
                <li>その他、ご相談に応じます。</li>
                <li>料金には消費税が含まれます。 </li>
              </ul>
            </div>
          </div>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact04 />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
